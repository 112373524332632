<template>
    <div>
        <h1>Portfolio</h1>
        <p>Welcome to my portfolio page</p>
        <p>Coming Soon...</p>
    </div>
</template>

<script>
export default {
    name: 'PortfolioApp'
}
</script>

<style scoped>
h1 {
    color: #42b983;
}
</style>