<template>
  <Portfolio />
</template>

<script>
import Portfolio from './views/PortfolioApp.vue';

export default {
  name: 'App',
  components: {
    Portfolio
  }
}
</script>

<style>
html,body {
  margin: 0;
  padding: 0;
  font-family: 'Kanit', sans-serif;
}
</style>
